import { useEffect } from "react";
import { Fragment } from "react";
import { meetdetails, meetinfo } from "../../../services/meetservice";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import { useState } from "react";
import moment from "moment";
import imageUrl from "../../../ImageUrl";
import fileString from "../../../assets/language/filepath.json";
import exportUrl from "../../../Export";
function ViewMeet() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tourname, setTourname] = useState("");
  const [tourloc, setTourloc] = useState("");
  const [tourdate, setTourdate] = useState("");
  const [mcode, setMCode] = useState("");

  const [sdob, setSDOB] = useState("");
  const [edob, setEDOB] = useState("");
  const [cdate, setCdate] = useState("");
  const [odate, setOdate] = useState("");
  const [mtype, setMeetType] = useState("");
  const [type, setType] = useState("");
  const [etype, setEType] = useState("");

  const [rfees, setRFees] = useState("");
  const [gateway, setGateway] = useState("");
  const [aentry, setAdditional] = useState("");
  const [free, setFTour] = useState("");
  const [hfees, setHFees] = useState("");
  const [overage, setOverAge] = useState("");
  const [category, setCategory] = useState("");
  const [multi, setMulti] = useState("");
  const [payment, setPayment] = useState("");

  const [logo, setLogo] = useState("");

  const [cir, setCircular] = useState("");
  const [cir2, setCircular2] = useState("");
  const [cir3, setCircular3] = useState("");
  const [cir4, setCircular4] = useState("");
  const [cir5, setCircular5] = useState("");

  useEffect(() => {
    meetinfo(id).then((response) => {
      if (response.status === true) {
        setTourname(response.data.tournament.meet_name);
        setTourloc(response.data.tournament.location);
        setTourdate(
          moment(response.data.tournament.start_date).format("DD/MM/YYYY") +
            " - " +
            moment(response.data.tournament.end_date).format("DD/MM/YYYY")
        );

        setMCode(response.data.tournament.meet_code);
        if (response.data.tournament.dob_start_date !== null) {
          setSDOB(
            moment(response.data.tournament.dob_start_date).format("DD/MM/YYYY")
          );
        }
        if (response.data.tournament.dob_end_date !== null) {
          setEDOB(
            moment(response.data.tournament.dob_end_date).format("DD/MM/YYYY")
          );
        }

        setCdate(
          moment(response.data.tournament.entry_deadline_date).format(
            "DD/MM/YYYY"
          )
        );
        if (response.data.tournament.entry_opens !== null) {
          setOdate(
            moment(response.data.tournament.entry_opens).format("DD/MM/YYYY")
          );
        }
        setType(response.data.tournament.meet_type);
        setMeetType(response.data.tournament.type);
        setEType(response.data.tournament.entry_type);

        setRFees(response.data.tournament.relay_fees);
        if (response.data.tournament.gateway_charges === "N") {
          setGateway("No");
        } else {
          setGateway("Yes");
        }

        if (response.data.tournament.allow_additional_entry === "N") {
          setAdditional("No");
        } else {
          setAdditional("Yes");
        }

        if (response.data.tournament.free_tournament === "N") {
          setFTour("No");
        } else {
          setFTour("Yes");
        }

        if (response.data.tournament.per_head_fees === "N") {
          setHFees("No");
        } else {
          setHFees("Yes");
        }

        if (response.data.tournament.overage_applied_athletes === "N") {
          setOverAge("No");
        } else {
          setOverAge("Yes");
        }

        if (response.data.tournament.multiple_events_allowed_flag === "N") {
          setMulti("No");
        } else {
          setMulti("Yes");
        }
        if (
          response.data.tournament.payment_gateway === "RP" ||
          response.data.tournament.payment_gateway === "Razor Pay"
        ) {
          setPayment("Razor Pay");
        } else {
          setPayment("CC Avenue");
        }

        setCategory(response.data.tournament.categories);
        setLogo(
          imageUrl + fileString.tournamentfiles + response.data.tournament.logo
        );

        setCircular(response.data.tournament.circular_file_1);
        if (response.data.tournament.circular_file_2 !== null) {
          if (response.data.tournament.circular_file_2 !== "-") {
            setCircular2(response.data.tournament.circular_file_2);
          }
        }

        if (response.data.tournament.circular_file_3 !== null) {
          if (response.data.tournament.circular_file_3 !== "-") {
            setCircular3(response.data.tournament.circular_file_3);
          }
        }

        if (response.data.tournament.circular_file_4 !== null) {
          if (response.data.tournament.circular_file_4 !== "-") {
            setCircular4(response.data.tournament.circular_file_4);
          }
        }

        if (response.data.tournament.circular_file_5 !== null) {
          if (response.data.tournament.circular_file_5 !== "-") {
            setCircular5(response.data.tournament.circular_file_5);
          }
        }
      }
    });
  }, []);

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/logo/logo-small.png");
    ev.target.onerror = null;
  }

  function exportidcardprint(){
    window.open(exportUrl + "idcardprintednationalfedsententries/" + id, "_self");
  }

  function exportidcardreport(){
    window.open(exportUrl + "idcardnationalfedsententries/" + id, "_self");

  }
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">View Meet</h2>
        </div>
      </div>
      <Card className=" custom-card overflow-hidden">
        <Card.Header>
          <Row>
            <Col xl={1} lg={12} className=" d-none d-xl-block custom-leftnav">
              <div className="main-img-user avatar-xl online text-center">
                <img
                  alt="avatar"
                  className="rounded-circle"
                  src={logo}
                  onError={(e) => errorhandle(e)}
                />
              </div>
            </Col>
            <Col xl={11} lg={12} className="text-center">
              <h4 className="mb-0 mt-3">{tourname}</h4>
            </Col>
          </Row>

          <div className="text-center">
            <div className="eventdet ps-2">
              <h6>
                <i className="fa fa-map-marker me-2 text-danger"></i>
                {tourloc}
              </h6>
            </div>
            <div className="eventdet ps-2">
              <h6>
                <i className="fa fa-calendar me-2 text-danger"></i>
                {tourdate}
              </h6>
            </div>
          </div>
        </Card.Header>
        <Card.Body className="addmeet">
          <div className="row mx-0 row-sm">
            <div className="col-md-6">
              <div className="profile-group">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse">Meet Code</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{mcode}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="profile-group"></div>
            </div>

            <div className="col-md-6">
              <div className="profile-group">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse">Entry Open Date</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{odate}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="profile-group">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse">Entry Close Date</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{cdate}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="profile-group">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse">Type</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{mtype}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="profile-group">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse">Entry Type</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{etype}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="profile-group">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse">Start DOB</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{sdob}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="profile-group">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse">End DOB</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{edob}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="profile-group">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse">Relay Fees</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{rfees}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="profile-group">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse">Gateway Charge</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{gateway}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="profile-group">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse">Additional Entry</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{aentry}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="profile-group">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse">Free Tournament</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{free}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="profile-group">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse">Per Head Fees</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{hfees}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="profile-group">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse">Over Age Applied</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{overage}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="profile-group">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse">Categories</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{category}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="profile-group">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse">Multi Events Allowed</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{multi}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="profile-group">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse">Payment Gateway</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{payment}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          {mtype === "National Meet" && etype !== "Federation" && (
            <div className="text-left">
              <a
                className="btn btn-danger me-2 mt-2"
                onClick={() => navigate("/superadmin/nationalallentries/" + id)}
              >
                All Entries
              </a>
              <a
                className="btn btn-secondary me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nationalsententries/" + id)
                }
              >
                Sent Entries
              </a>
              <a
                className="btn btn-success me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nationalpendingentries/" + id)
                }
              >
                Pending Entries
              </a>
              <a
                className="btn btn-dark me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/meeteditbasicinformation/" + id)
                }
              >
                Edit Basic Information
              </a>
              <a
                className="btn btn-warning me-2 mt-2"
                onClick={() => navigate("/superadmin/editagecategory/" + id)}
              >
                Edit Age Category
              </a>
              <a
                className="btn btn-print me-2 mt-2"
                onClick={()=> window.open(
                  exportUrl + "idcardprintednationalindividualsententries/" + id,
                  "_blank"
                )}
              >
                ID Card Print
              </a>
              <a
                className="btn btn-info me-2 mt-2"
                onClick={()=> window.open(
                  exportUrl + "idcardnationalindividualsententries/" + id,
                  "_blank"
                )}
              >
                ID Card Report
              </a>
              <a
                className="btn btn-warning me-2 mt-2"
                onClick={()=> window.open(
                  exportUrl + "nationalindividualsent-qrcodegen/" + id,
                  "_blank"
                )}
              >
                Generate QR Code
              </a>
              <a
                className="btn btn-secondary me-2 mt-2"
                onClick={()=> window.open(
                  exportUrl + "nationalindividualsentphoto/" + id,
                  "_blank"
                )}
              >
                Generate Photo
              </a>
              <a
                className="btn btn-back mt-2"
                onClick={() => navigate("/superadmin/listmeet")}
              >
                Back
              </a>
            </div>
          )}
          {mtype === "National Meet" && etype === "Federation" && type !== 8 &&(
            <div className="text-left">
              

              <a
                className="btn btn-dark me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nationalsentfederation/" + id)
                }
              >
                Sent Federation
              </a>
              <a
                className="btn btn-danger me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nationalathleteentries/" + id)
                }
              >
                Athletes
              </a>
              <a
                className="btn btn-info me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nationalofficialentries/" + id)
                }
              >
                Officials
              </a>
              <a
                className="btn btn-success me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nationalarrivalentries/" + id)
                }
              >
                Arrival Details
              </a>
              <a
                className="btn btn-warning me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nationaldepartureentries/" + id)
                }
              >
                Departure Details
              </a>
              <a
                className="btn btn-secondary me-2 mt-2"
                onClick={() => navigate("/superadmin/nationalmosentries/" + id)}
              >
                MOS
              </a>
              <a
                className="btn btn-print me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nationalperformancceentries/" + id)
                }
              >
                Performance
              </a>
              <a
                className="btn btn-secondary me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/meeteditbasicinformation/" + id)
                }
              >
                Edit Basic Information
              </a>
              <a
                className="btn btn-warning me-2 mt-2"
                onClick={() => navigate("/superadmin/editagecategory/" + id)}
              >
                Edit Age Category
              </a>
              <a
                className="btn btn-dark me-2 mt-2"
                onClick={exportidcardprint}
              >
                ID Card Print
              </a>
              <a
                className="btn btn-danger me-2 mt-2"
                onClick={exportidcardreport}
              >
                ID Card Report
              </a>
              <a
                className="btn btn-success me-2 mt-2"
                onClick={() =>
                {window.open(exportUrl + "reapplyidcardnationalfedsententries/" + id, "_self");}
                }
              >
                Reapply ID Card Data
              </a>
              <a
                className="btn btn-back mt-2"
                onClick={() => navigate("/superadmin/listmeet")}
              >
                Back
              </a>
            </div>
          )}
          {mtype === "National Meet" && etype === "Federation" && type === 8 &&(
            <div className="text-left">
              <a
                className="btn btn-dark me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nidjamsentfederation/" + id)
                }
              >
                Sent Federation
              </a>
              <a
                className="btn btn-danger me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nidjamathleteentries/" + id)
                }
              >
                Athletes
              </a>
              <a
                className="btn btn-info me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nidjamofficialentries/" + id)
                }
              >
                Officials
              </a>
              <a
                className="btn btn-success me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nidjamarrivalentries/" + id)
                }
              >
                Arrival Details
              </a>
              <a
                className="btn btn-warning me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nidjamdepartureentries/" + id)
                }
              >
                Departure Details
              </a>
              <a
                className="btn btn-secondary me-2 mt-2"
                onClick={() => navigate("/superadmin/nidjammosentries/" + id)}
              >
                MOS
              </a>
              <a
                className="btn btn-info me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nidjamperformancceentries/" + id)
                }
              >
                Performance
              </a>
              <a
                className="btn btn-success me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nidjampresscuttingentries/" + id)
                }
              >
                Press Cuttings
              </a>
              <a
                className="btn btn-warning me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nidjamphotoentries/" + id)
                }
              >
                Photos of Meet
              </a>
              <a
                className="btn btn-print me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/nidjamaccountdetails/" + id)
                }
              >
                Account Details
              </a>
              <a
                className="btn btn-secondary me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/meeteditbasicinformation/" + id)
                }
              >
                Edit Basic Information
              </a>
              <a
                className="btn btn-danger me-2 mt-2"
                onClick={() => navigate("/superadmin/editagecategory/" + id)}
              >
                Edit Age Category
              </a>
              <a
                className="btn btn-dark me-2 mt-2"
                onClick={exportidcardprint}
              >
                ID Card Print
              </a>
              <a
                className="btn btn-print me-2 mt-2"
                onClick={exportidcardreport}
              >
                ID Card Report
              </a>
              <a
                className="btn btn-back mt-2"
                onClick={() => navigate("/superadmin/listmeet")}
              >
                Back
              </a>
            </div>
          )}
          {mtype !== "National Meet" && (
            <div className="text-left">
              <a
                className="btn btn-danger me-2 mt-2"
                onClick={() => navigate("/superadmin/stateallentries/" + id)}
              >
                All Entries
              </a>
              <a
                className="btn btn-secondary me-2 mt-2"
                onClick={() => navigate("/superadmin/statesententries/" + id)}
              >
                Sent Entries
              </a>
              <a
                className="btn btn-success me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/statependingentries/" + id)
                }
              >
                Pending Entries
              </a>
              <a
                className="btn btn-dark me-2 mt-2"
                onClick={() =>
                  navigate("/superadmin/meeteditbasicinformation/" + id)
                }
              >
                Edit Basic Information
              </a>
              <a
                className="btn btn-warning me-2 mt-2"
                onClick={() => navigate("/superadmin/editagecategory/" + id)}
              >
                Edit Age Category
              </a>
              <a
                className="btn btn-back mt-2"
                onClick={() => navigate("/superadmin/listmeet")}
              >
                Back
              </a>
            </div>
          )}

          <hr />
          <Row className="row mx-0">
            <Col xl={2} lg={12} className="text-center">
              <a
                href={imageUrl + fileString.tournamentfiles + cir}
                target={"_blank"}
              >
                <img
                  src={require("../../../assets/images/pdf.png")}
                  className="meetcircular"
                />
              </a>
              <span>Circular</span>
            </Col>
            {cir2 !== "" && (
              <Col xl={2} lg={12} className="text-center">
                <a
                  href={imageUrl + fileString.tournamentfiles + cir2}
                  target={"_blank"}
                >
                  <img
                    src={require("../../../assets/images/pdf.png")}
                    className="meetcircular"
                  />
                </a>
                <span>Circular 2</span>
              </Col>
            )}
            {cir3 !== "" && (
              <Col xl={2} lg={12} className="text-center">
                <a
                  href={imageUrl + fileString.tournamentfiles + cir3}
                  target={"_blank"}
                >
                  <img
                    src={require("../../../assets/images/pdf.png")}
                    className="meetcircular"
                  />
                </a>
                <span>Circular 3</span>
              </Col>
            )}

            {cir4 !== "" && (
              <Col xl={2} lg={12} className="text-center">
                <a
                  href={imageUrl + fileString.tournamentfiles + cir4}
                  target={"_blank"}
                >
                  <img
                    src={require("../../../assets/images/pdf.png")}
                    className="meetcircular"
                  />
                </a>
                <span>Circular 4</span>
              </Col>
            )}

            {cir5 !== "" && (
              <Col xl={2} lg={12} className="text-center">
                <a
                  href={imageUrl + fileString.tournamentfiles + cir5}
                  target={"_blank"}
                >
                  <img
                    src={require("../../../assets/images/pdf.png")}
                    className="meetcircular"
                  />
                </a>
                <span>Circular 5</span>
              </Col>
            )}
          </Row>
        </Card.Footer>
      </Card>
    </Fragment>
  );
}

export default ViewMeet;
